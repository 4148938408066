/* global $, _, Backbone */

export const HistoryTimelineView = Backbone.View.extend({
  events: {
    'mouseenter .timeline__years li': 'toggleYear',
    'mouseleave': 'resetYear',
    'mouseenter.caption': 'showCaption',
    'mouseleave.caption': 'hideCaption',
    'click .timeline__years li': 'scrollToYear'
  },

  initialize: function () {
    var year = this.$el
      .find('.timeline__years [data-year-initial]')
      .attr('data-year');

    this.wayPoint = this.$el.offset()
      ? this.$el.offset().top + this.$el.height() : 0;

    this.model = new Backbone.Model({
      year: year,
      caption: true
    });

    this.initialYear = year;
    this.listenTo(this.model, 'change', this.render);

    $(window).on('scroll', _.bind(this.toggleNav, this));

    // Füge den resize-Event-Listener hinzu
    $(window).on('resize', _.debounce(_.bind(this._onResize, this), 100));

    this.render();
    this.toggleNav();

    // Setze das initiale Hintergrundbild basierend auf dem höchsten Jahr
    this._setInitialBackgroundImage();
  },

  render: function () {
    this._reset();
    this._setContentYear();
    this._setYearPositions(); // Berechne und setze Positionen der Jahre
    this._setTimelineYear();
    this._toggleCaption();
  },

  toggleYear: function (evt) {
    var $target = $(evt.currentTarget);
    this.model.set({ year: $target.attr('data-year') });

    // Setze das Hintergrundbild beim Hover auf das entsprechende Bild
    var bgImage = $target.data('bg-image');
    this.$el.find('.timeline__content').css('background-image', bgImage);
  },

  resetYear: function () {
    this.model.set({ year: this.initialYear });

    // Setze das Hintergrundbild zurück auf das initiale Bild
    var $initialYear = this.$el.find('.timeline__years li').first(); // Höchstes Jahr
    var bgImage = $initialYear.data('bg-image');
    this.$el.find('.timeline__content').css('background-image', bgImage);
  },

  showCaption: function () {
    this.model.set({ caption: true });
  },

  hideCaption: function () {
    this.model.set({ caption: false });
  },

  scrollToYear: function () {
    var year = this.model.get('year');
    window.location.hash = 'year-' + year;
  },

  toggleNav: function () {
    var top = $(window).scrollTop();
    $('[data-timeline-back]').toggle(top > this.wayPoint, 'slow');
  },

  _setContentYear: function () {
    this.$el.find('.timeline__content').attr('data-year', this.model.get('year'));

    // Übertrage den Inhalt von data-bg-image des aktiven Jahres auf das Hintergrundbild von .timeline__content
    var $activeYear = this.$el.find('li[data-year="' + this.model.get('year') + '"]');
    var bgImage = $activeYear.data('bg-image');
    this.$el.find('.timeline__content').attr('style', bgImage);
  },

  _setTimelineYear: function () {
    var $year = this.$el.find('li[data-year="' + this.model.get('year') + '"]');
    $year.addClass('hover');
    $year.find('.caption').show();
  },

  _toggleCaption: function () {
    var $year = this.$el.find('li[data-year="' + this.model.get('year') + '"]');
    $year.find('.caption').toggle(this.model.get('caption'));
  },

  _reset: function () {
    this.$el.find('.timeline__years li.hover').removeClass('hover');
    this.$el.find('.timeline__years li > .caption').hide();
  },

  _setInitialBackgroundImage: function () {
    var $initialYear = this.$el.find('.timeline__years li').first(); // Höchstes Jahr
    var bgImage = $initialYear.data('bg-image');
    this.$el.find('.timeline__content').attr('style', bgImage);
  },

  _setYearPositions: function () {
    var $years = this.$el.find('.timeline__years li');
    var $container = this.$el.find('.timeline');
    var isMobile = $(window).width() <= 1199; // Mobiles Layout bei Bildschirmbreite <= 768px

    if (isMobile) {
      // Mobiles Layout: Positionierung und Abstände über CSS regeln
      var itemHeight = 20; // Höhe jedes li-Elements, inkl. margin-bottom (20px)
      
      $years.each((index, element) => {
          var topPosition = index * itemHeight;
  
          $(element).css({
              'left': 'auto',  // Entferne den left-Wert
          });
          $(element).find('.caption').show(); // Zeige alle Captions an
      });
  
      // Passe die Höhe des Containers an, um alle Elemente aufzunehmen
      var totalHeight = $years.length * itemHeight;
      $container.css('height', totalHeight + 'px');
  } else {
        // Desktop Layout: Standardmäßige Positionierung
        var containerWidth = $container.width();

        // Sortiere die Jahre nach der Jahreszahl (aufsteigend)
        $years = $years.sort((a, b) => {
            return parseInt($(a).data('year'), 10) - parseInt($(b).data('year'), 10);
        });

        // Dynamisch das erste und letzte Jahr ermitteln
        var firstYear = parseInt($years.first().data('year'), 10);
        var lastYear = parseInt($years.last().data('year'), 10);

        // Berechne die Gesamtlänge der Zeitspanne in Jahren
        var totalYears = lastYear - firstYear;

        // Mindestabstand in Prozent (z.B. 2% des Containers)
        var minGapPercent = (50 / containerWidth) * 100; // 50px Mindestabstand in Prozent umgerechnet

        // Berechne die Positionen und speichere sie in einem Array
        var positions = [];
        $years.each((index, element) => {
            var year = parseInt($(element).data('year'), 10);

            // Berechne die relative Position basierend auf dem Jahr
            var relativePosition = (year - firstYear) / totalYears;
            var position = relativePosition * 100;

            if (index > 0) {
                var prevYear = parseInt($years.eq(index - 1).data('year'), 10);
                var yearDifference = year - prevYear;

                // Berechne den Abstand basierend auf der tatsächlichen Differenz
                var yearGap = ((yearDifference / totalYears) * 100) + 8;

                // Überprüfe, ob der Mindestabstand eingehalten wird
                if (yearGap < minGapPercent) {
                    yearGap = minGapPercent;
                }

                position = positions[index - 1] + yearGap;
            }

            positions.push(position);
        });

        // Normalisiere die Positionen auf den Bereich von 0% bis 100%
        var maxPosition = Math.max(...positions);
        if (maxPosition > 100) {
            positions = positions.map(pos => (pos / maxPosition) * 100);
        } else if (maxPosition < 100) {
            // Falls das letzte Element nicht bei 100% ist, skaliere alle Positionen auf 100%
            positions = positions.map(pos => (pos / maxPosition) * 100);
        }

        // Setze die berechneten und normalisierten Positionen auf die li-Elemente
        $years.each((index, element) => {
            $(element).css({
                'left': positions[index] + '%',
            });
            $(element).find('.caption').hide(); // Verstecke Captions standardmäßig
        });
    }
  },

  _onResize: function () {
    this._setYearPositions();
  }
});
